import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../../utils/typography"
import Layout from "../../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class RepairRankFoodCartDxJaPage extends React.Component {
    componentDidMount() {

    }

    render() {
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>屋台 デラックスの修繕ランク一覧 │ Cookin’Burger</title>
          <meta name="description" content='ショップNFTの耐久値を修繕することで修繕値が増加し修繕ランクが上昇していきます。
                      修繕ランクによって耐久値1当たりの修繕に必要な費用が変わります。修繕ランクはショップNFTの種類によって異なります。' />
          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="屋台 デラックスの修繕ランク一覧 - Cookin’Burger" />
          <meta property="og:description" content="ショップNFTの耐久値を修繕することで修繕値が増加し修繕ランクが上昇していきます。
                      修繕ランクによって耐久値1当たりの修繕に必要な費用が変わります。修繕ランクはショップNFTの種類によって異なります。" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="屋台 デラックスの修繕ランク一覧 - Cookin’Burger" />
          <meta name="twitter:description" content="ショップNFTの耐久値を修繕することで修繕値が増加し修繕ランクが上昇していきます。
                      修繕ランクによって耐久値1当たりの修繕に必要な費用が変わります。修繕ランクはショップNFTの種類によって異なります。" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
        <div className="l-main">
          <div className="section-inner_m">
            <div
              className="breadcrumb-list wow fadeInUp"
              data-wow-delay="0.1s"
              data-wow-duration="0.7s"
              data-wow-offset="0"
            >
              <ul>
                <li>
                  <Link to={`/ja`}>TOP</Link>
                </li>
                <li>
                  <Link to={`/ja/repairrank`}>修繕ランク</Link>
                </li>
                <li>屋台 デラックス</li>
              </ul>
            </div>
          </div>

          <div className="l-about-section">
            <div className="section-inner_m">

              <div className="l-about-section-box">
                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../../assets/img-ja/title-03.png" alt="修繕ランク" />
                </div>

                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-caps wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  屋台 デラックスの修繕ランク一覧
                </p>

                <div>
                  <img src="../../../assets/img-ja/repairrank/foodcart_dx.png" alt="What's Cookin’ Burger?" />
                </div>
              </div>

              <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                  <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-tw.png" alt="" /></a></li>
                  <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                  <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

RepairRankFoodCartDxJaPage.propTypes = propTypes

export default RepairRankFoodCartDxJaPage

export const pageQuery = graphql`
  query RepairRankFoodCartDxJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
